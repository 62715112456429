<template>
  <div>
    <slot
      :error="error"
      :form="form"
      :message="message"
      :on-submit="onSubmit"
    />
  </div>
</template>

<script>
import Form from './form'
import * as tracking from './tracking'
import http from './http'

export default {
  components: {},
  props: {
    defaultData: {
      type: Object,
      default: () => ({})
    },
    trackingData: {
      type: Object,
      default: () => ({})
    },
    action: {
      type: String,
      default: '/contact'
    }
  },
  data: () => ({
    form: {},
    message: '',
    error: ''
  }),
  created () {
    this.form = new Form(this.defaultData)
  },
  methods: {
    async onSubmit () {
      this.message = ''
      this.error = ''

      try {
        const { data } = await http.post(this.action, this.form.data())
        tracking.send(this.trackingData)

        this.message = data.message
        this.form.reset(this.defaultData)
      } catch (error) {
        if (error.response.status === 422) {
          this.form.errors.set(error.response.data.errors)
          return
        }
        if (error.response.status === 404) {
          this.error = 'Unable to reach API'
          return
        }
        if (error.response.status === 500) {
          this.error = error.response.data.message
        }
      }
    }
  }
}
</script>
