import Errors from './errors'

class Form {
  constructor (fields = {}) {
    this.fields = fields
    this.errors = new Errors()
  }

  reset (defaults = {}) {
    this.fields = defaults
    this.errors.clear()
  }
  data () {
    return Object.assign({}, this.fields)
  }
}

export default Form
