export const send = (data) => {
  const {
    trackingEvent,
    trackingCategory,
    trackingLabel = false,
    trackingValue = false
  } = data

  if (typeof window.gtag === 'function') {
    window.gtag(
      'event',
      trackingEvent,
      {
        'event_category': trackingCategory,
        ...(trackingLabel && { 'event_label': trackingLabel }),
        ...(trackingValue && { 'value': trackingValue })
      }
    )
  }
}
